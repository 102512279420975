import  { createApp } from 'vue';
import App from './App.vue'
import MainPanel from './components/MainPanel.vue'
import router from './router'


const app = createApp(App).use(router).use(router).use(router)
app.use(router)
app.component('main-panel', MainPanel)
app.mount('#app')

