import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../pages/HomePage.vue'
import LoginPage from '../pages/LoginPage.vue'
import CoordinateAnnotationPage from '../pages/CoordinateAnnotationPage'
import SymbolAnnotationPage from '../pages/SymbolAnnotationPage'

/**
 * Check to make sure that we're authenticated before accessing a protected route
 */

 function authenticate(to, from, next) {
  if (sessionStorage.getItem('idToken') !== null) {
      next();
  } else {
      next('/login');
  }
}


export const routes = [
  {
      path: '/login', 
      component: LoginPage
  },
  {
      path: '/coordinate-annotation', 
      component: CoordinateAnnotationPage, 
      beforeEnter: authenticate
  },
  { 
      path: '/symbol-annotation', 
      component: SymbolAnnotationPage, 
      beforeEnter: authenticate
  },
  {
      path: '/', 
      component: HomePage, 
      beforeEnter: authenticate
  }
]


const router = createRouter({
  routes,
  history: createWebHistory(), 
  scrollBehavior (to, from, savedPosition) {
      return savedPosition
  }
})

export default router
