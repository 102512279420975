<template>
    <div class="annotation-div">
        <button class="home-button" @click="goHome()">🏠</button>
        <div class="button-div" v-show="ready">
            <button class="s1-button" @click="record(1)">x</button>
            <button class="s2-button" @click="record(2)">o</button>
            <button class="s3-button" @click="record(3)">|</button>
            <button class="s4-button" @click="record(4)">-</button>
        </div>
        <div class="image-div" v-show="ready">
            <img class="annotation-image" 
                 :src="imageData" 
                 alt="[none]" 
                 v-if="imageData.length > 0">
        </div>
        <div class="next-button-div" v-show="!ready">
            <button class="next-button" @click="setNextImg()">Next Image</button>
        </div>
        <div class="discard-button-div" v-show="ready">
            <button class="discard-button" @click="discard()">Discard</button>
        </div>
    </div>
</template>

<script>
import calls from '../calls'
export default {
    data() {
        return {
            imageName: '',
            imageData: '',
            ready: false
        }
    },
    methods: {
        goHome() {
            this.$router.push('/');
        },
        setNextImg() {
            calls.fetchSymbolImage().then(response => {
                // const url = window.URL.createObjectURL(new Blob([response.data]));
                // this.imageData = url;
                // this.imageName = response.headers['content-disposition']
                // .replace('inline; filename=', '').replaceAll('"', '');
                console.log(response);
                this.imageData = response.data.link;
                this.imageName = response.data.name;
            }).catch(err => alert(err));
            this.ready = true;
        },
        record(symbolId) {
            this.ready = false;
            const payload = {name: this.imageName, label: symbolId};
            calls.recordSymbol(payload);
            this.clear();
        },
        discard() {
            // TODO: delete on annotation side
            if (confirm('Are you sure you want to discard this image?')) {
                this.clear();
            }
        },
        clear() {
            this.ready = false;
            this.imageData = '';
            this.imageName = '';
        }
    }
}
</script>

<style scoped>
    .home-button {
        position: fixed;
        top: 5px;
        left: 5px;
        width: 50px;
    }
    .button-div {
        position: fixed;
        top: 50px;
        left: 50%;
        transform: translate(-50%);
    }
    button {
        height: 50px;
        width: 150px;
        margin: 10px;
    }
    .annotation-div {
        text-align: center;
    }
    .image-div {
        width: 128px;
        height: 128px;
        position: fixed;
        top: 200px;
        left: 50%;
        transform: translate(-50%);
    }
    .image-div:hover {
        cursor: crosshair;
    }
    .discard-button-div {
        position: fixed;
        top: 400px;
        left: 50%;
        transform: translate(-50%);
    }
    .annotation-image {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;
    }
    #annotation-canvas {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 20;
    }
    .next-button-div {
        position: fixed;
        top: 10vh;
        left: 50%;
        transform: translate(-50%);
    }
    .next-button {
        width: 200px;
        height: 100px;
    }
</style>