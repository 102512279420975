<template>
    <div class="button-div">
        <button @click="setAnnotationPage('coordinate')">Annotate Coordinates</button>
        <button @click="setAnnotationPage('symbol')">Annotate Symbols</button>
        <button @click="logout()">Logout</button>
    </div>
</template>

<script>
export default {
    methods: {
        setAnnotationPage(task) {
            if (task == 'coordinate') {
                this.$router.push({path: '/coordinate-annotation'});
            } else {
                this.$router.push({path: '/symbol-annotation'});
            }
        },
        logout() {
            localStorage.clear();
            this.$router.push('/login');
        }
    }
}
</script>

<style scoped>

    button {
        display: block;
        margin: auto;
        margin-top: 20px;

    }
</style>