import axios from 'axios'

const client = axios.create();
client.interceptors.request.use(
    
)

export default {
    fetchCoordinateImage() {
        return this.retryOnExpired(this.postFn('fetch-coordinate-image', {}));
    },
    fetchSymbolImage() {
        return this.retryOnExpired(this.postFn('fetch-symbol-image', {}));
    },
    recordCoordinate(payload) {
        return this.retryOnExpired(this.postFn('record-coordinate', payload));
    },
    recordSymbol(payload) {
        return this.retryOnExpired(this.postFn('record-symbol', payload));
    },
    postFn(route, payload) {
        const token = sessionStorage.getItem('idToken');
        const config = {
            headers: {
                authorization: 'Bearer ' + token
            }
        };
        return () => axios.post('https://gateway.audax.dev/' + route, payload, config);
    },
    retryOnExpired(call) {
        return call().catch(err => {
            if (err.response && (err.response.status == 401 || err.response.status == 403)) {
                const payload = {grant_type: 'refresh_token', refresh_token: sessionStorage.getItem('refreshToken')};
                axios.post('https://securetoken.googleapis.com/v1/token?key=AIzaSyBXx0QtZbw4DzXetRmCJjH-nDe0kURkH9c', payload)
                .then(response => {
                    const idToken = response.data.id_token;
                    sessionStorage.setItem('idToken', idToken);
                    call().catch(err => alert(err));
                }).catch(() => {
                    alert('Failed to refresh token')
                });
            } else {
                alert(err);
            }
        })
    }
}