<template>
    <div class="annotation-div">
        <button class="home-button" @click="goHome()">🏠</button>
        <div class="button-div" v-show="ready">
            <button class="undo-button" @click="undo()" :disabled="points.length == 0">Undo</button>
            <button class="record-button" @click="record()" :disabled="points.length != 4">Record</button>
        </div>
        <div class="image-div" v-show="ready">
            <img class="annotation-image" 
                 :src="imageData" 
                 alt="[none]">
            <canvas id="annotation-canvas" ref="annotation-canvas" @click="setLabelPoint($event)"></canvas>
        </div>
        <div class="next-button-div" v-show="!ready">
            <button class="next-button" @click="setNextImg()">Next Image</button>
        </div>
        <div class="discard-button-div" v-show="ready">
            <button class="discard-button" @click="discard()">Discard</button>
        </div>
    </div>
</template>

<script>
import calls from '../calls'
export default {
    data() {
        return {
            imageName: '',
            imageData: '',
            points: [],
            ready: false
        }
    },
    created() {
        window.addEventListener('keydown', this.keyEventUndo);
    },
    unmounted() {
        window.removeEventListener('keydown', this.keyEventUndo);
    },
    mounted() {
        document.getElementById('annotation-canvas').setAttribute('width', 648);
        document.getElementById('annotation-canvas').setAttribute('height', 648);
    },
    methods: {
        goHome() {
            this.$router.push('/');
        },
        assembleLabel() {
            const xAvg = this.points.map(point => point.x).reduce((a, b) => a + b, 0) / 4.0;
            const yAvg = this.points.map(point => point.y).reduce((a, b) => a + b, 0) / 4.0;
            const tl = this.points.find(point => point.x < xAvg && point.y < yAvg);
            const tr = this.points.find(point => point.x > xAvg && point.y < yAvg);
            const bl = this.points.find(point => point.x < xAvg && point.y > yAvg);
            const br = this.points.find(point => point.x > xAvg && point.y > yAvg);
            return {
                topLeft: {
                    x: Math.round(tl.x / 2.0),
                    y: Math.round(tl.y / 2.0)
                },
                topRight: {
                    x: Math.round(tr.x / 2.0),
                    y: Math.round(tr.y / 2.0)
                },
                bottomLeft: {
                    x: Math.round(bl.x / 2.0),
                    y: Math.round(bl.y / 2.0)
                },
                bottomRight: {
                    x: Math.round(br.x / 2.0),
                    y: Math.round(br.y / 2.0)
                }
            }
        },
        undo() {
            if (this.points.length > 0) {
                let c = this.$refs['annotation-canvas'];
                const canvas = c.getContext('2d');
                const lastPoint = this.points[this.points.length - 1];
                canvas.clearRect(lastPoint.x - 5, lastPoint.y - 5, 10, 10);
                this.points.pop();
            }
        },
        keyEventUndo(event) {
            if (event.ctrlKey && event.code === 'KeyZ') {
                this.undo();
            }
        },
        setNextImg() {
            calls.fetchCoordinateImage().then(response => {
                // const url = window.URL.createObjectURL(new Blob([response.data]));
                // this.imageData = url;
                // this.imageName = response.headers['content-disposition']
                // .replace('inline; filename=', '').replaceAll('"', '');
                console.log(response);
                this.imageData = response.data.link;
                this.imageName = response.data.name;
            }).catch(err => alert(err));
            this.ready = true;
        },
        setLabelPoint(e) {
            if (this.points.length < 4) {
                const rect = e.target.getBoundingClientRect();
                const x = e.clientX - rect.left;
                const y = e.clientY - rect.top;
                this.points.push({
                    x: x,
                    y: y
                });
                let c = this.$refs['annotation-canvas'];
                const canvas = c.getContext('2d');
                canvas.fillStyle = "red";
                canvas.beginPath();
                canvas.arc(x, y, 3, 0, 2.0 * Math.PI, false);
                canvas.stroke();
                canvas.fill();
            }
        },
        record() {
            const payload = {
                name: this.imageName,
                label: this.assembleLabel()
            };
            calls.recordCoordinate(payload);
            this.clear();
        },
        discard() {
            // TODO: delete on annotation side (maybe)
            if (confirm('Are you sure you want to discard this image?')) {
                this.clear();
            }
        },
        clear() {
            this.ready = false;
            this.imageName = '';
            this.imageData = '';
            this.points = [];
            let canvas = this.$refs['annotation-canvas'];
            const context = canvas.getContext('2d');
            context.clearRect(0, 0, canvas.width, canvas.height);
        }
    }
}
</script>

<style scoped>
    .home-button {
        position: fixed;
        top: 5px;
        left: 5px;
        width: 50px;
    }
    .button-div {
        position: fixed;
        top: 15px;
        left: 50%;
        transform: translate(-50%);
    }
    button {
        height: 50px;
        width: 150px;
        margin: 10px;
    }
    button:disabled {
        color: rgb(100, 100, 100);
    }
    button:disabled:hover {
        color: rgb(100, 100, 100);
        cursor: default;
    }
    button:disabled:active {
        background-color: rgb(70, 70, 70);
    }
    .annotation-div {
        text-align: center;
    }
    .image-div {
        width:648px;
        height: 648px;
        position: fixed;
        top: 100px;
        left: 50%;
        transform: translate(-50%);
    }
    .image-div:hover {
        cursor: crosshair;
    }
    .discard-button-div {
        position: fixed;
        top: 758px;
        left: 50%;
        transform: translate(-50%);
    }
    .annotation-image {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;
    }
    #annotation-canvas {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 20;
    }
    .next-button-div {
        position: fixed;
        top: 10vh;
        left: 50%;
        transform: translate(-50%);
    }
    .next-button {
        width: 200px;
        height: 100px;
    }
</style>